import { createApp } from 'vue'
import '@vant/touch-emulator';
import App from './App.vue'
import store from './store'
import router from './router'
import Antd from 'ant-design-vue';
import { Overlay, Loading, Pagination, Cascader, Step, Steps, Dialog, Tabbar, TabbarItem, NavBar, Grid, GridItem, Icon, Swipe, SwipeItem, NoticeBar, PullRefresh, List, ActionBar, ActionBarIcon, ActionBarButton, Field, Checkbox, CheckboxGroup, Cell, CellGroup, Col, Row, Popup, Toast, Button, Tab, Tabs, Image as VanImage, Tag, RadioGroup, Radio,Lazyload  } from 'vant';
import less from 'less';
import 'vant/lib/index.css';
import 'ant-design-vue/dist/reset.css';

const app = createApp(App).use(store)
app.use(router)
app.use(store)
app.use(less)
app.use(NoticeBar)
app.use(Lazyload)
app.use(Antd);
app.use(Overlay);
app.use(Loading);
app.use(Pagination);
app.use(Cascader);
app.use(Step);
app.use(Steps);
app.use(Dialog);
app.use(Radio);
app.use(RadioGroup);
app.use(Tag);
app.use(VanImage);
app.use(Button);
app.use(Tab);
app.use(Tabs);
app.use(ActionBar);
app.use(ActionBarIcon);
app.use(ActionBarButton);
app.use(Field);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Cell);
app.use(CellGroup);
app.use(Col);
app.use(Row);
app.use(Popup);
app.use(Toast);
app.use(List);
app.use(PullRefresh);
app.use(Swipe);
app.use(SwipeItem);
app.use(NoticeBar);
app.use(Icon);
app.use(Grid);
app.use(GridItem);
app.use(NavBar);
app.use(Tabbar);
app.use(TabbarItem);

app.mount('#app')