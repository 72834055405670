import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    key: 'Vuex-Persistence',
    storage: window.localStorage,
    reducer: state => ({
        token: state.token, // 这个就是存入localStorage的值
        WXTempKey: state.WXTempKey,
        realName: state.realName,
        webConfig: state.webConfig,
        invite:state.invite
    })
})
export default createStore({
    state: {
        token: "",
        WXTempKey: '',
        isLogin: false,
        uid: 0,
        name: '',
        avatar: '',
        realName: false,
        webConfig: [],
        invite:""
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setShiming(state, realName) {
            state.realName = realName
        },
        setLogin(state, flag) {
            state.isLogin = flag
        },
        setConfig(state, config) {
            state.webConfig = config
        },
        setInvite(state, uid) {
            state.invite = uid
        },
    },

    actions: {},
    plugins: [vuexLocal.plugin]
})